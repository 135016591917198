p.active {
  color: #007bff;
}

.badge {
  font-size: 60%;
  padding-top: 6px;
  padding-bottom: 4px;
  width: 41px;
}

.p-sidebar {
  font-size: 14px;
}

.nav-link {
  padding: 4px 1rem;
}

.table td, .table th {
  padding: .25rem;
}

[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active, [class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:hover, [class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:focus {
  background: unset;
  color: #007bff;
}

a.nav-link.nav-custom.nav-link-active {
  background-color: #007bff!important;
  color: #fff!important;
}
