// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
// @import 'variables';

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// Admin-lte
@import 'admin-lte';

//Fontawesome
@import '@fortawesome/fontawesome-free/css/all';

//Prims
@import '../css/prism';

//Custom
@import '../css/custom';
